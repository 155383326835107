import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import MediaHero from '../components/MediaHero';
import DeviceSection from '../components/DeviceSection';
import DemoBanner from '../components/DemoBanner';
import Testimonial from '../components/Testimonial';
import SideBySideSection from '../components/SideBySideSection';
import ResourcesSection from '../components/ResourcesSection';
import { deviceDataFromDeviceAsset } from '../utils/contentfulHelpers';
import SiteMeta from '../components/SiteMeta';

const FeaturesPage = ({ data }) => {
  const {
    metaTitle,
    metaDescription: { metaDescription },
    heroModule,
    featuresIntroModule,
    // testimonial,
    demoCallout,
    featuredResourcesTitle,
    featuredResourcesSubheaderText: { featuredResourcesSubheaderText },
    featuredResourcesCtaLink,
    featuredResourcesCtaText,
    featuredResources,
    planModule,
    // createModule,
    shareModule,
    learnModule } = data.contentfulFeaturesPage;

  const resourcesData = {
    title: featuredResourcesTitle,
    subtitle: featuredResourcesSubheaderText,
    ctaText: featuredResourcesCtaText,
    ctaUrl: featuredResourcesCtaLink,
    featuredResources: featuredResources
  };


  const featuresIntroModuleDeviceData = deviceDataFromDeviceAsset(featuresIntroModule.deviceAsset)

  const sectionsData = [
    { ...planModule, barTheme: 'orange' },
    { ...learnModule, barTheme: 'transparent' },
    { ...shareModule, barTheme: 'yellow' },
    // { ...createModule, barTheme: 'orange' }
  ];

  return (
    <Layout>
      <SiteMeta title={metaTitle} description={metaDescription} />
      <MediaHero {...heroModule} />
      <DeviceSection {...featuresIntroModule} layoutVariant="deviceRight"
        backgroundTheme="white" barTheme="yellow"
        {...featuresIntroModuleDeviceData} />
      {/* <Testimonial {...testimonial} /> */}
      <DemoBanner {...demoCallout} />
      {sectionsData.map((section, index) => {
        const layout = index % 2 ? 'mediaRight' : 'mediaLeft';
        return <SideBySideSection {...section} layoutVariant={layout} key={index} />
      })}
      <ResourcesSection {...resourcesData} />
    </Layout>
  );
};

export default FeaturesPage;

export const query = graphql`
  query {
    contentfulFeaturesPage{
      metaTitle
      metaDescription {
        metaDescription
      }
      slug
      heroModule {
        ...Navigator
      }
      featuresIntroModule {
        ...DeviceNavigator
      }
      demoCallout {
        ...Navigator
      }
      # testimonial {
      #   testimonialImage {
      #     fluid(maxWidth: 1200, quality: 80, toFormat: JPG) {
      #       ...GatsbyContentfulFluid_withWebp_noBase64
      #     }
      #   }
      #   testimonialTitle
      #   testimonialQuote {
      #     testimonialQuote
      #   }
      #   testimonialAttribution
      #   testimonialAttributionIcon {
      #     fluid(maxWidth: 151, quality: 100) {
      #       ...GatsbyContentfulFluid_withWebp_noBase64
      #     }
      #   }
      # }
      planModule {
        ...Navigator
      }
      learnModule {
        ...Navigator
      }
      # createModule {
      #   ...Navigator
      # }
      shareModule {
        ...Navigator
      }
      featuredResources {
        ...ResourceCard
      }
      featuredResourcesCtaText
      featuredResourcesTitle
      featuredResourcesCtaLink
      featuredResourcesSubheaderText {
        featuredResourcesSubheaderText
      }
    }
  }
`;
